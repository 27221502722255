import React from "react";
import { CTA } from "./cta";

export const Team = (props) => {
  let style = {};
  if (props.title !== "Our Services") {
    style = {
      background: "#f6f6f6",
    };
  }

  return (
    <div id="team" className="text-center" style={style}>
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>{props.title}</h2>
          <p>{props.description}</p>
        </div>
        <div id="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-3 col-sm-6 team">
                  <div className="thumbnail">
                    <img
                      src={d.img}
                      alt="..."
                      className="team-img"
                      style={{ height: "135px", width: "unset" }}
                    />
                    <div className="caption">
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
      <CTA />
    </div>
  );
};
